(function () {
    'use strict'
    //on load refesh serverside validation
    var faildServerSideChecks = document.querySelectorAll(".input-validation-error");
    Array.prototype.slice.call(faildServerSideChecks).forEach(function(faildServerSideCheck){
       faildServerSideCheck.classList.add("is-invalid");
    });

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('form')
    console.log("forms",forms);
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                /*reset custom validity */
                Array.prototype.slice.call(form.querySelectorAll("input, select, textarea"))
                    .forEach(function (input) {
                        input.setCustomValidity("");
                        input.classList.remove("is-invalid");
                    });

                /*do custom validationrules*/
                var customValidationInputs = form.querySelectorAll("[data-customvalidation-type]");
                Array.prototype.slice.call(customValidationInputs)
                    .forEach(function (input) {
                        var validity = input.validity;
                        if (validity.valid) {
                            var validationType = input.getAttribute('data-customvalidation-type');
                            switch (validationType) {
                                case "comparewith": {
                                    var mode = input.getAttribute('data-customvalidation-comparewith-mode');
                                    var otherFieldID = input.getAttribute('data-customvalidation-comparewith-fieldid');
                                    var otherField = document.getElementById(otherFieldID);
                                    switch (mode) {
                                        case "ge": {
                                            if (!(Number(input.value) >= Number(otherField.value))) {
                                                input.setCustomValidity("customerror");
                                            }
                                            break;
                                        }
                                        case "le": {
                                            if (!(Number(input.value) <= Number(otherField.value))) {
                                                input.setCustomValidity("customerror");
                                            }
                                            break;
                                        }
                                        case "e": {
                                            if (!(Number(input.value) == Number(otherField.value))) {
                                                input.setCustomValidity("customerror");
                                            }
                                            break;
                                        }
                                        default: {
                                            console.log("Validationmode comparewith with mode '" + mode + "' is not implemented yet.");
                                            break;
                                        }
                                    }
                                    break;
                                }
                                default: {
                                    console.log("Validationtype '" + validationType + "' is not implemented yet.");
                                    break;
                                }
                            }
                        }
                    });

                /*Check if form is valid */
                if (!form.checkValidity()) {
                    Array.prototype.slice.call(form.querySelectorAll("input, select, textarea"))
                        .forEach(function (input) {
                            var validity = input.validity;
                            if (!validity.valid) {
                                var valmessgeobjs = document.querySelectorAll("*[data-valmsg-for='" + input.name + "']");
                                var useErrormessage = null;
                                if (validity.valueMissing) {
                                    useErrormessage = 'data-errormessage-required';
                                }
                                if (validity.typeMismatch) {
                                    useErrormessage = 'data-errormessage-typemismatch';
                                }
                                if (validity.rangeUnderflow) {
                                    useErrormessage = 'data-errormessage-rangeUnderflow';
                                }
                                if (validity.badInput) {
                                    useErrormessage = 'data-errormessage-badInput';
                                }
                                if (validity.patternMismatch) {
                                    useErrormessage = 'data-errormessage-patternMismatch';
                                }
                                if (validity.rangeOverflow) {
                                    useErrormessage = 'data-errormessage-rangeOverflow';
                                }
                                if (validity.stepMismatch) {
                                    useErrormessage = 'data-errormessage-stepMismatch';
                                }
                                if (validity.tooLong) {
                                    useErrormessage = 'data-errormessage-tooLong';
                                }
                                if (validity.tooShort) {
                                    useErrormessage = 'data-errormessage-tooShort';
                                }
                                if (validity.customError) {
                                    useErrormessage = 'data-errormessage-customError';
                                }

                                if (useErrormessage) {
                                    Array.prototype.slice.call(valmessgeobjs)
                                        .forEach(function (validationmessage) {
                                            validationmessage.innerText = input.getAttribute(useErrormessage);
                                            validationmessage.setAttribute("data-showErrormessage", useErrormessage);
                                        });
                                }
                            }
                        });

                    event.preventDefault()
                    event.stopPropagation()
                } else {
                    console.log("valid")
                    window.FormLocker.lockForm(form);
                }
                form.classList.add('was-validated');
            }, false)
        })
})()